import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const ModalGeneralPurpose = ({
	isModalOpen,
	toggleModal,
	modalContent: ModalContent,
	modalSize,
}) => {
	return (
		<Modal size={modalSize} isOpen={isModalOpen} toggle={toggleModal}>
			<ModalHeader toggle={toggleModal}></ModalHeader>
			<ModalBody>{ModalContent && <ModalContent />}</ModalBody>
		</Modal>
	)
}

export default ModalGeneralPurpose
