import React, { useState, useEffect } from "react"
import { Modal, ModalBody } from "reactstrap"
import "./style.scss"
import { Link } from "react-router-dom"

const Index = (props) => {
	const [notLoggedIn, setNotLoggedIn] = useState(false)

	useEffect(() => {
		const accessString = localStorage.getItem("JWT")
		if (accessString === null) {
			setNotLoggedIn(true)
		}
	}, [])

	if (notLoggedIn)
		return (
			<div className="container ">
				<Modal
					size="lg"
					isOpen={props.modal}
					toggle={props.toggleModal}
					modalClassName="modal-cambridge-calendar"
				>
					<ModalBody className="temporarySign-container relative">
						<p
							onClick={props.close}
							className="absolute top-2 right-4 cursor-pointer"
						>
							Close
						</p>

						<Link to="/daycare">
							<img
								className="covid-19-poster"
								src="./images/daycare_passes_promo.gif"
								alt="poster"
							/>
						</Link>
					</ModalBody>
				</Modal>
			</div>
		)
	else {
		return null
	}
}

export default Index
