import { useEffect, useState } from "react"
import { jwtDecode } from "jwt-decode"
import { useLocation, useParams } from "react-router-dom"
import { FaUser, FaPaw, FaCalendar, FaCreditCard } from "react-icons/fa"
import { FaUserDoctor, FaScissors } from "react-icons/fa6"
import { CiEdit } from "react-icons/ci"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"
import PetFormByStaff from "../../pages/ClientRegistration/PetFormByStaff"
import UpdateVetModal from "../Modals/UpdateVet"
import PetInformationCard from "./PetCard"
import { petObj } from "../../utils/constants"
import LoadPage from "../LoadingPage"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "react-router-dom"
import MainCalendar from "../../components/Calendars/MainCalendar"
import VetDetailsForm from "../../pages/ClientRegistration/VetDetailsForm"
import {
	yesOrNoOptions,
	genderOptions,
	petTypeOptions,
} from "../../utils/constants"
import OwnerFormComponent from "../../pages/ClientRegistration/OwnerDetailsForm"
import ClientAppointments from "./ClientAppointments"
import axios from "axios"
import Select from "react-select"
import {
	optionsGroomers,
	optionsGroomersCambridge,
} from "../../pages/Schedule/Schedule.options"

export default function EditClients(props) {
	const location = useLocation()

	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [isLoadingPage, setIsLoadingPage] = useState(true)
	const [data, setData] = useState(null)
	const [error, setError] = useState(null)
	const [isPanelOpen, setPanelOpen] = useState(true)
	const [selectedPanel, setSelectedPanel] = useState("owner_pet") // Default view

	//Book Appointment
	const [dates, setDates] = useState({
		paola: new Date(),
		claudia: new Date(),
		peppa: new Date(),
		adriana: new Date(),
		mercedes: new Date(),
		bath_Cambridge: new Date(),
	})
	const [triggerFetchEvents, setTriggerFetchEvents] = useState(false)
	const [newGroomer, setNewGroomer] = useState("milton")
	const [bookAppointmentOpenModal, setBookAppointmentOpenModal] =
		useState(false)

	// Pet
	const [petToUpdate, setPetToUpdate] = useState(null)
	const [addPetOpenModal, setAddPetOpenModal] = useState(false)
	const [enrollmentType, setEnrollmentType] = useState(null)

	// Owner States
	const [isRejected, setIsRejected] = useState(false)
	const [lastName, setLastName] = useState("")
	const [firstName, setFirstName] = useState("")
	const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("")
	const [cellphone, setCellphone] = useState("")
	const [workPhone, setWorkPhone] = useState("")
	const [email, setEmail] = useState("")
	const [email2, setEmail2] = useState("")
	const [address, setAddress] = useState("")
	const [isOwnerButtonReady, setIsOwnerButtonReady] = useState(false)

	// Vet States
	const [vetOpenModal, setVetOpenModal] = useState(false)
	const [vetName, setVetName] = useState("")
	const [vetPhone, setVetPhone] = useState("")
	const [vetClinicName, setVetClinicName] = useState("")
	const [vetAddress, setVetAddress] = useState("")
	const [isVetButtonReady, setIsVetButtonReady] = useState(false)
	const [showAddVetForm, setShowAddVetForm] = useState(false)

	const { id } = useParams()

	useEffect(() => {
		fetchData()
	}, [])

	const redInputHandler = (value) => {
		return `${
			value == null || value == "" ? "border-red-input" : ""
		} mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500`
	}

	const handlePetChange = (property, value) => {
		setPetToUpdate({ ...petToUpdate, [property]: value })
	}

	const fetchData = async () => {
		try {
			const token = localStorage.getItem("JWT")

			const decodedToken = jwtDecode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				localStorage.clear()
				window.location.href = "/"
				return
			}

			const response = await axios.get("/auth/api/clients_cambridge/" + id, {
				headers: { JWT: token },
			})

			if (response && response.data) {
				setData(response.data)
				setIsRejected(response.data.isClientRejected)
				setFirstName(response.data.firstName)
				setLastName(response.data.lastName)
				setPrimaryPhoneNumber(response.data.primaryPhoneNumber)
				setCellphone(response.data.cellphone)
				setWorkPhone(response.data.workPhone)
				setAddress(response.data.address)
				setEmail(response.data.email)
				setEmail2(response.data.email)
				setEnrollmentType(
					response.data.signedDaycareRelease &&
						response.data.signedGroomingRelease
						? "full"
						: response.data.signedDaycareRelease
						? "daycare"
						: response.data.signedGroomingRelease
						? "grooming"
						: "grooming"
				)
			}
		} catch (error) {
			console.log(error.response)
			if (error.response && error.response.status === 401) {
				setError("Unauthorized access")
			} else {
				setError("An error occurred")
			}
		} finally {
			setIsLoadingPage(false)
		}
	}

	const handlePanelChange = (panel) => {
		setSelectedPanel(panel)
	}
	const handleRejectChange = (event) => {
		setIsRejected(event.target.checked)
	}

	const updateOwnerInfo = async (e) => {
		e.preventDefault()
		const token = localStorage.getItem("JWT")
		if (!lastName || !firstName || !primaryPhoneNumber) {
			console.log(firstName, lastName, primaryPhoneNumber, email, address)

			return alert(`Missing required fields`)
		}
		if (email !== email2) {
			return alert(`Emails don't match`)
		}
		setLoadingAxiosReq(true)
		const updatedOwner = {
			isClientRejected: isRejected,
			firstName: firstName.replace(/^./, (str) => str.toUpperCase()),
			lastName: lastName.replace(/^./, (str) => str.toUpperCase()),
			primaryPhoneNumber: primaryPhoneNumber.replace(/[- )(]/g, ""),
			cellphone: cellphone.replace(/[- )(]/g, ""),
			workPhone: workPhone.replace(/[- )(]/g, ""),
			address: address,
			email: email,
			city: "cambridge",
		}

		await axios
			.put(`/api/clients/${data.id}`, updatedOwner, {
				headers: { Authorization: `JWT ${token}` },
			})
			.then(() => {
				fetchData()
				setTimeout(() => {
					setLoadingAxiosReq(false)
				}, 1000)
			})
			.catch((error) => {
				setLoadingAxiosReq(false)
				console.log(error)
			})
	}

	const addVet = async () => {
		if (!vetName || !vetPhone || !vetClinicName || !vetAddress) {
			return alert("ALl fields are mandatory for Vets")
		}
		const vetObj = {
			vetName,
			vetPhone,
			vetClinicName,
			vetAddress,
		}
		await axios
			.post(`/api/vets/${data.id}`, vetObj)
			.then(() => console.log("Vet added"))
			.catch((err) => console.log(err))
	}
	if (isLoadingPage) {
		return <div className="mt-48 text-4xl text-center">Loading...</div>
	}
	if (error) {
		return <h1 className="mt-24 text-center">Unauthorized access!</h1>
	}

	const handleCalendarNavigate = (calendarId, newDate) => {
		setDates((prevDates) => ({
			...prevDates,
			[calendarId]: newDate,
		}))
	}

	return (
		<div>
			<Link
				className="absolute left-0 px-4 text-lg rounded-md hover:bg-cyan-200 sm:top-16 top-32 bg-cyan-300"
				to={"/auth/reception/cambridge"}
			>
				&#x2190; Back
			</Link>

			{/* Mobile Menu */}
			<div
				className={`bg-black text-white sm:h-[120px] md:h-[100px] lg:hidden p-4 w-full flex justify-center`}
			>
				<ul className="flex flex-row justify-between mt-4 mb-0 text-md">
					<li
						className={`cursor-pointer pr-2 my-4 mr-4 ${
							selectedPanel === "owner_pet" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("owner_pet")}
					>
						<span>
							<span className="block text-center">
								<span className="inline-block">Client Info</span>
							</span>
						</span>
					</li>
					<li
						className={`cursor-pointer mt-4 ${
							selectedPanel === "appointments" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("appointments")}
					>
						{isPanelOpen ? (
							<span>
								<FaCalendar className="inline-block mr-2" />
								Appointments
							</span>
						) : (
							<FaCalendar className="mr-2" />
						)}
					</li>
					<li
						className={`cursor-pointer ml-4 mt-4 ${
							selectedPanel === "account" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("account")}
					>
						{isPanelOpen ? (
							<span>
								<FaCreditCard className="inline-block mr-2" />
								Client Balance
							</span>
						) : (
							<FaCreditCard className="mr-2" />
						)}
					</li>
				</ul>
			</div>

			<div className="flex h-screen bg-gray-100 md:mt-20 lg:mt-18">
				{/* Left side Menu Desktop */}
				<div
					className={`bg-white hidden md:block p-4 w-1/7 transition-all duration-300`}
				>
					<ul className="text-xl">
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "owner_pet" && "text-pink-600"
							}`}
							onClick={() => handlePanelChange("owner_pet")}
						>
							<span className="block text-center">
								<FaUser className="inline-block mr-1" />
								<FaPaw className="inline-block mr-1" />
								<FaUserDoctor className="inline-block mr-1" />
							</span>
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "appointments" && "text-pink-600"
							}`}
							onClick={() => handlePanelChange("appointments")}
						>
							<span className="flex text-center">
								<FaCalendar className="inline-block mr-1" />
								<FaScissors className="inline-block mr-1" />
								<FaPaw className="inline-block mr-1" />
							</span>
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "account" && "text-pink-600"
							}`}
							onClick={() => handlePanelChange("account")}
						>
							<FaCreditCard className="mr-2" />
						</li>
					</ul>
				</div>

				{/* Right Side Content */}
				<div className="flex-grow">
					<div className="flex flex-row justify-between">
						<p className="text-3xl">
							<span className="px-4 py-1 text-sm text-white rounded-sm bg-pink md:text-2xl">
								Client Number: <strong>{data && data.id}</strong>
							</span>
						</p>
						<div>
							<span
								className={`py-1 mr-1 px-2 rounded-sm ${
									data && data.signedGroomingRelease
										? "bg-green-200 text-gray"
										: "bg-yellow-400"
								}`}
							>
								Grooming{" "}
								{data && data.signedGroomingRelease ? (
									<span>&#10003;</span>
								) : (
									<span>&#10005;</span>
								)}
							</span>
							<span
								className={`py-1 px-2 rounded-sm ${
									data && data.signedDaycareRelease
										? "bg-green-200 text-gray"
										: "bg-yellow-400"
								}`}
							>
								Daycare{" "}
								{data && data.signedDaycareRelease ? (
									<span>&#10003;</span>
								) : (
									<span>&#10005;</span>
								)}
							</span>
						</div>
					</div>
					{/* Owner */}
					{selectedPanel === "owner_pet" && data && (
						<div>
							<div
								className={`flex flex-col gap-2 lg:flex-row ${
									isRejected
										? "border-8 border-red-500 border-solid"
										: "border border-gray-300"
								}`}
							>
								<div className="p-4 md:w-full lg:w-1/3 card">
									<div className="flex flex-row justify-between border-b border-b-slate-300">
										<h5>Owner Information</h5>
										<div className="flex flex-row items-center">
											<label
												className={`${
													isRejected ? "text-red-500" : "text-green-500"
												} `}
												htmlFor="rejectClient"
											>
												{`${
													isRejected
														? "Client is Rejected"
														: "Client is Accepted"
												}`}
											</label>
											<input
												type="checkbox"
												id="rejectClient"
												checked={isRejected}
												onChange={handleRejectChange}
												className="ml-2 mb-2 h-6 w-6"
											/>
										</div>
									</div>
									{/* OWNER SECTION */}
									<OwnerFormComponent
										redInputHandler={redInputHandler}
										isOwnerButtonReady={isOwnerButtonReady}
										setLastName={setLastName}
										setFirstName={setFirstName}
										setPrimaryPhoneNumber={setPrimaryPhoneNumber}
										setCellphone={setCellphone}
										setWorkPhone={setWorkPhone}
										setAddress={setAddress}
										setEmail={setEmail}
										setEmail2={setEmail2}
										lastName={data && data.lastName}
										firstName={data && data.firstName}
										primaryPhoneNumber={data && data.primaryPhoneNumber}
										cellphone={data && data.cellphone}
										workPhone={data && data.workPhone}
										address={data && data.address}
										email={data && data.email}
										email2={email2}
										isNotCard={true}
										isCustomMargin={true}
									/>
									<button
										onClick={updateOwnerInfo}
										className="mt-4 btn btn-block btn-info"
									>
										<FaUser className="inline-block mr-1" /> Update Owner Info
									</button>
									<hr />
									{selectedPanel === "owner_pet" && !data.VetCambridge && (
										<div className="mt-4 text-center">
											<p className="text-lg">
												This client has not added the Vet information yet
											</p>
											<div className="w-1/3 mx-auto">
												<img
													className="w-full"
													src="/images/vet.jpg"
													alt="vet"
												/>
											</div>
											<ScrollLink
												activeClass="active"
												to="add_vet_button"
												spy={true}
												smooth={true}
												offset={-70}
												duration={500}
											>
												<button
													onClick={() => setShowAddVetForm(!showAddVetForm)}
													className="w-full mt-2 btn btn-warning"
												>
													{showAddVetForm
														? "Hide Vet Form"
														: "Add Vet Information"}
												</button>
											</ScrollLink>
											{/* ADD VET FORM */}
											{showAddVetForm && (
												<>
													<VetDetailsForm
														redInputHandler={redInputHandler}
														vetName={vetName}
														vetPhone={vetPhone}
														vetClinicName={vetClinicName}
														vetAddress={vetAddress}
														setVetName={setVetName}
														setVetPhone={setVetPhone}
														setVetClinicName={setVetClinicName}
														setVetAddress={setVetAddress}
														isVetButtonReady={isVetButtonReady}
													/>

													<button
														onClick={addVet}
														className="w-full btn-warning btn "
													>
														Add Vet
													</button>
												</>
											)}
											<span id="add_vet_button"></span>
										</div>
									)}
									{/* VET SECTION */}
									{data && data.VetCambridge && (
										<div className="mt-4">
											<div className="flex flex-row justify-between border-b border-b-slate-300">
												<h5>Vet Information</h5>
											</div>
											<div className="mt-4">
												<p className="capitalize">
													Clinic Name:{" "}
													{data &&
														data.VetCambridge &&
														data.VetCambridge.vetClinicName}
												</p>
												<p className="capitalize">
													Vet Name:{" "}
													{data.VetCambridge && data.VetCambridge.vetName}
												</p>
												<p>
													Vet Phone:{" "}
													{data.VetCambridge && data.VetCambridge.vetPhone}
												</p>
												<p>
													Vet Clinic Address:{" "}
													{data.VetCambridge && data.VetCambridge.vetAddress}
												</p>
												<button
													onClick={() => setVetOpenModal(true)}
													className="w-full px-4 py-2 mb-2 text-white rounded-sm bg-cyan-600"
												>
													<CiEdit className="inline-block mr-3" />
													Edit Vet{" "}
													<FaUserDoctor className="inline-block ml-1" />
												</button>
											</div>
										</div>
									)}
								</div>

								<div className="w-full lg:w-2/3">
									<button
										onClick={() => {
											setPetToUpdate(petObj)
											setAddPetOpenModal(true)
										}}
										className="w-48 px-4 py-2 mb-2 text-white bg-orange-600 rounded-sm"
									>
										<i className="fas fa-plus"></i> Add a Pet{" "}
										<i className="fas fa-paw"></i>
									</button>
									<button
										onClick={() => {
											//   setPetToUpdate(petObj);
											setBookAppointmentOpenModal(true)
										}}
										className="px-4 py-2 mb-2 ml-2 text-white rounded-sm bg-cyan-300 hover:bg-cyan-200"
									>
										<i className="fas fa-plus"></i> Book Appointment
									</button>

									{data &&
										data.PetCambridges &&
										data.PetCambridges.map((pet) => {
											if (!pet.deletedAt) {
												return (
													<PetInformationCard
														commentsData={pet.CommentCambridges}
														key={pet.id}
														pet={pet}
														setAddPetOpenModal={setAddPetOpenModal}
														fetchData={fetchData}
														enrollmentType={enrollmentType}
														setEnrollmentType={setEnrollmentType}
													/>
												)
											}
										})}
								</div>
							</div>
						</div>
					)}
					{/* Appointments */}
					{selectedPanel === "appointments" && (
						<div>
							<ClientAppointments
								clientPhone1={data.primaryPhoneNumber}
								clientPhone2={data.cellphone}
								clientPhone3={data.workPhone}
								clientEmail={data.email}
							/>
						</div>
					)}
					{/* Client Balance */}
					{selectedPanel === "account" && (
						<h3 className="mt-4">Client Account Coming Soon...</h3>
					)}{" "}
				</div>

				{/* VET MODAL */}
				{data && data.Vet && (
					<UpdateVetModal
						vetId={data && data.VetCambridge.id}
						modalToUpdateVet={vetOpenModal}
						toggleModalToUpdateVet={() => setVetOpenModal(!vetOpenModal)}
						vetName={data && data.VetCambridge && data.VetCambridge.vetName}
						vetPhone={data && data.VetCambridge && data.VetCambridge.vetPhone}
						vetClinicName={
							data && data.VetCambridge && data.VetCambridge.vetClinicName
						}
						vetAddress={
							data && data.VetCambridge && data.VetCambridge.vetAddress
						}
						getVetInformation={fetchData}
						locationSelected={"milton"}
						clientID={data && data.id}
					/>
				)}

				{/* Add Pet */}
				<Modal
					size="lg"
					isOpen={addPetOpenModal}
					toggle={() => setAddPetOpenModal(!addPetOpenModal)}
				>
					<ModalBody>
						<div className="flex justify-between">
							<button
								className="px-2 py-1 text-white rounded-md bg-pink"
								onClick={() =>
									setEnrollmentType(
										enrollmentType === "daycare" || enrollmentType === "full"
											? "grooming"
											: "daycare"
									)
								}
							>
								Click here for{" "}
								{enrollmentType === "daycare" || enrollmentType === "full"
									? "grooming"
									: "daycare"}{" "}
								Details{" "}
								{enrollmentType === "daycare" && (
									<span>
										only <FaScissors className="inline-block" />
									</span>
								)}
							</button>
							<p className="px-2 text-lg font-bold text-white capitalize bg-purple-500">
								{enrollmentType === "full" ? "Full enrollment" : enrollmentType}
							</p>
						</div>
						<PetFormByStaff
							redInputHandler={redInputHandler}
							pet={petToUpdate}
							index={0}
							yesOrNoOptions={yesOrNoOptions}
							genderOptions={genderOptions}
							petTypeOptions={petTypeOptions}
							handlePetChange={handlePetChange}
							isPetReady={false}
							formType={"add"}
							mode={enrollmentType === "full" ? "daycare" : enrollmentType}
							updatePetHandler={() => {}}
							vetId={data && data.VetCambridge && data.VetCambridge.id}
							clientId={data && data.id}
							setAddPetOpenModal={setAddPetOpenModal}
							fetchData={fetchData}
							setLoadingAxiosReq={setLoadingAxiosReq}
							city="cambridge"
						/>
					</ModalBody>
				</Modal>

				{loadingAxiosReq && <LoadPage />}
			</div>
			<Modal
				size="lg"
				modalClassName="modal-cambridge-calendar"
				isOpen={bookAppointmentOpenModal}
				toggle={() => setBookAppointmentOpenModal(!bookAppointmentOpenModal)}
			>
				<ModalHeader
					toggle={() => setBookAppointmentOpenModal(!bookAppointmentOpenModal)}
				>
					Booking appointment: <br />{" "}
					{/* Client ID:<b>{data && data.id}</b> <br /> Phone:{" "}
          <b>{data && data.primaryPhoneNumber}</b> - Pets:{" "}
          <b>
            {data &&
              data.PetCambridges &&
              data.PetCambridges.map((pet) => pet.name).join(", ")}
          </b> */}
				</ModalHeader>
				<ModalBody>
					<div className="">
						<Select
							className="mb-2"
							placeholder="Switch Groomer"
							onChange={(value) => {
								setNewGroomer(value.value)
								console.log(value)
								setTriggerFetchEvents(true)
								setTimeout(() => {
									setTriggerFetchEvents(false)
								}, 1000)
							}}
							options={
								location.pathname.includes("cambridge")
									? optionsGroomersCambridge
									: optionsGroomers
							}
						/>
						<MainCalendar
							isBather={false}
							city="cambridge"
							groomer={
								newGroomer === "milton" ? "peppa" : newGroomer.toLowerCase()
							}
							title={`${data && data.primaryPhoneNumber} - ${data && data.id}`}
							breedName={`${
								data.PetCambridges && data.PetCambridges[0].breed
							} - ${data.PetCambridges && data.PetCambridges[0].name}`}
							date={
								!newGroomer.toLowerCase()
									? dates.peppa
									: newGroomer.toLowerCase() === "claudia"
									? dates.claudia
									: newGroomer.toLowerCase() === "paola"
									? dates.paola
									: newGroomer.toLowerCase() === "adriana"
									? dates.adriana
									: newGroomer.toLowerCase() === "mercedes"
									? dates.mercedes
									: newGroomer.toLowerCase() === "peppa"
									? dates.peppa
									: dates.bath_Cambridge
							}
							onNavigate={(newDate) =>
								handleCalendarNavigate("adriana", newDate)
							}
							triggerFetchEvents={triggerFetchEvents}
							setTriggerFetchEvents={setTriggerFetchEvents}
							step={15}
							timeSlots={2}
							customHeight={800}
							standardControls
							min={new Date(2019, 10, 0, 7, 0, 0)}
							max={new Date(2019, 10, 0, 19, 0, 0)}
							extraDetails={
								data && {
									id: data.id,
									phone: data.primaryPhoneNumber,
									pets: data.PetCambridges,
								}
							}
						/>
					</div>
				</ModalBody>
			</Modal>
		</div>
	)
}
