import React, { useState, useEffect, useContext } from "react"
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from "react-icons/ai"
import "./style.scss"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import moment from "moment-timezone"
import axios from "axios"
import {
	optionsIsNewClient,
	optionsDogServices,
	optionsDogBath,
} from "./Schedule.options"
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Input,
	Label,
	Col,
} from "reactstrap"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { Helmet } from "react-helmet"
import Tag from "../../components/BookingForm/Tag"
import Validation from "../../components/BookingForm/FrmScreens/Validation"
import City from "../../components/BookingForm/FrmScreens/City"
import PetTypeScreen from "../../components/BookingForm/FrmScreens/PetTypeScreen"
import DogSizeScreen from "../../components/BookingForm/FrmScreens/DogSizeScreen"
import DogServices from "../../components/BookingForm/FrmScreens/DogServices"
import CatServices from "../../components/BookingForm/FrmScreens/CatServices"
import Groomers from "../../components/BookingForm/FrmScreens/Groomers"
import ImageGradient from "../../components/ImageGradient"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
// import io from "socket.io-client"

// const socket = io.connect(process.env.REACT_APP_SOCKET_ENDPOINT)

moment.tz.setDefault("America/Toronto")
moment.locale("en-CA")
const localizer = momentLocalizer(moment)

const Schedule = () => {
	const [showPetType, setShowPetType] = useState(true)
	const [petType, setPetType] = useState("")
	const [dogSize, setDogSize] = useState("")
	const [groomer, setGroomer] = useState("")
	const [petName, setPetName] = useState("")
	const [breed, setBreed] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [email, setEmail] = useState("")
	const [modalToSchedule, setModalToSchedule] = useState(false)
	const [modalToEditEvent, setModalToEditEvent] = useState(false)
	const [datesFiltered, setDatesFiltered] = useState([])
	const [editStart, setEditStart] = useState("")
	const [editEnd, setEditEnd] = useState("")
	const [eventToEdit, setEventToEdit] = useState("")
	const [showDogService, setShowDogService] = useState(false)
	const [showCatServices, setShowCatServices] = useState(false)
	const [showDogSize, setShowDogSize] = useState(false)
	const [showGroomers, setShowGroomers] = useState(false)
	const [dogService, setDogService] = useState(false)
	const [service, setService] = useState("")
	const [catService, setCatService] = useState(false)
	const [isNewClient, setIsNewClient] = useState("")
	const [errorMsg, setErrorMsg] = useState("")
	const [showInstructions, setShowInstructions] = useState(false)
	const [modalSuccess, setModalSuccess] = useState(false)
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [city, setCity] = useState("")
	const [finalAsnwers, setFinalAsnwers] = useState([])
	const [soonestAppointment, setSoonestAppointment] = useState(null)

	const { setShowRegisterGroomingModal } = useContext(RegistrationContext)

	useEffect(() => {
		localStorage.removeItem("GROOMER")
		// socket.emit("join_room", "client_online")
	}, [])

	// useEffect(() => {
	// 	socket.on("receive_message", (data) => {
	// 		console.log(data + "This spot has been taken")
	// 	})
	// }, [socket])

	// const [newBookingToast, setNewBookingToast] = useState(false);
	// const [bookingDetailsInProcess, setBookingDetailsInProcess] =
	// 	useState(undefined);

	// useEffect(() => {
	// 	socket = io(CONNECTION_PORT, { transports: ["websocket"] });
	// 	connectToRoom();
	// }, []);

	// const messageToSend = async (msg) => {
	// 	const msgObj = {
	// 		room: ROOM,
	// 		content: { message: msg },
	// 	};
	// 	await socket.emit("send_message", msgObj);
	// };

	// const connectToRoom = () => {
	// 	socket.emit("join_calendars", ROOM);
	// };

	// useEffect(() => {
	// 	socket.on("receive_message", (data) => {
	// 		console.log(data);
	// 		console.log("Groomer: ", groomer);
	// 		console.log("City: ", city);
	// 		if (data.groomer === props.groomer) {
	// 			setNewBookingToast(true);
	// 		}
	// 		refetchEvents();
	// 	});
	// 	// eslint-disable-next-line
	// }, []);

	const resetPetType = () => {
		setShowPetType(true)
		setShowDogService(false)
		setShowGroomers(false)
		setShowInstructions(false)
		setShowDogSize(false)
		setShowCatServices(false)
		setDogService("")
		setCatService("")
		setPetType("")
		setDogSize("")
		setGroomer("")
		setPetName("")
		setBreed("")
		setPhoneNumber("")
		setModalToSchedule(false)
		setModalToEditEvent(false)
		setModalSuccess(false)
		setDatesFiltered([])
		setEditStart("")
		setEditEnd("")
		setEventToEdit("")
		setIsNewClient("")
		setErrorMsg("")
	}

	const toggleModal = () => {
		if (modalToSchedule) return
		if (!modalToSchedule) {
			setShowInstructions(false)
			// setShowGroomers(true);
		}

		setModalToSchedule(!modalToSchedule)
	}

	// 	//Toggle to edit appointments on calendar
	const toggleToEdit = async () => {
		setModalToEditEvent(!modalToEditEvent)
		if (!modalToEditEvent) {
			setErrorMsg("")
			setPetName("")
			setBreed("")
			setPhoneNumber("")
			setErrorMsg("")
		}
		// const obj = {
		// 	isBeingBooked: false,
		// 	id: eventToEdit.id,
		// };
		// await axios
		// 	.put(`/schedule/calendar/update/${eventToEdit.id}`, obj)
		// 	.then((res) => {
		// 		console.log(res);
		// 		// messageToSend("Updating db");
		// 	})
		// 	.catch((err) => console.log(err));
	}
	{
	}

	const selectedCity =
		city === "milton"
			? "264 Bronte St S, Unit 15, Milton, L9T 5A3"
			: "383 Elgin St N. Unit #1, Cambridge"
	const onSelectedDogSize = (value) => {
		setDogSize(value.value)
		setShowDogService(true)
		setShowDogSize(false)
	}

	const onSelectedDogServices = (value) => {
		setService(value.value)
	}

	const onSelectedCatServices = (value) => {
		setCatService(value.value)
		setShowCatServices(false)

		//As this is the last step, we can set the final answers for cat.
		const answersAux = [
			// City
			{
				answer: city === "milton" ? "Milton" : "Cambridge",
				image: "/images/city-icon.png",
				bigImage: true,
				onClick: () => {
					resetPetType()
					setCity("")
				},
			},
			// Pet Type
			{
				answer: petType,
				image:
					petType === "Dog" ? "/images/wet-dog.png" : "/images/wet-cat.png",
				bigImage: true,
				onClick: () => {
					toggleShowPetType()
				},
			},
			// Cat Service
			{
				answer: value.value,
				image:
					value.value === "Cat Premium Pkg"
						? "/images/premium-icon.png"
						: value.value === "Cat Haircut Pkg"
						? "/images/grooming-icon.png"
						: "/images/bath-icon.png",
				bigImage: false,
				onClick: () => {
					toggleShowCatService()
				},
			},
			// Groomer
			{
				answer: groomer,
				image: `/images/${groomer.toLowerCase()}-main.png`,
				bigImage: true,
				onClick: () => {},
			},
		]
		setFinalAsnwers(answersAux)
	}

	const onSelectedIsNewClient = async (value) => {
		setIsNewClient(value.value)
	}

	const onSelectGroomer = (value) => {
		console.log("Value", value)
		setShowGroomers(false)
		setGroomer(value)
		localStorage.setItem("GROOMER", value)

		//As this is the last step, we can set the final answers for dog.
		//Cat services can only be done by one person so we can set the final answers for cat when selecting service
		const answersAux = [
			// City
			{
				answer: city === "milton" ? "Milton" : "Cambridge",
				image: "/images/city-icon.png",
				bigImage: true,
				onClick: () => {
					resetPetType()
					setCity("")
				},
			},
			// Pet Type
			{
				answer: petType,
				image:
					petType === "Dog" ? "/images/wet-dog.png" : "/images/wet-cat.png",
				bigImage: true,
				onClick: () => {
					toggleShowPetType()
				},
			},
			// Dog Size
			{
				answer:
					dogSize === "appRequest"
						? "Small Size"
						: dogSize === "mediumDogRequest"
						? "Medium Size"
						: dogSize === "bigDogRequest"
						? "	Large/X-Lg Size"
						: dogSize === "bigDogRequest"
						? "Large/X-Lg Size"
						: "",
				image: "/images/size-dog.png",
				bigImage: false,
				onClick: () => {
					toggleShowDogSize()
				},
			},
			// Dog Service
			{
				answer: dogService,
				image:
					dogService === "bath"
						? "/images/bath-icon.png"
						: "/images/grooming-icon.png",
				bigImage: false,
				onClick: () => {
					toggleShowDogService()
				},
			},
			// Groomer
			{
				answer: value,
				image: `/images/${value.toLowerCase()}-main.png`,
				bigImage: true,
				onClick: () => {
					if (value !== "Bather") {
						toggleShowGroomers()
					}
				},
			},
		]
		setFinalAsnwers(answersAux)
	}
	const convertDate = (date) => {
		return moment.utc(date).toDate()
	}

	// const refetchEvents = async () => {
	// 	const groomerActive = localStorage.getItem("GROOMER");
	// 	const cityActive = localStorage.getItem("CITY");
	// 	const petTypeActive = localStorage.getItem("PET_TYPE");

	// 	await axios
	// 		.get(
	// 			`/schedule/calendars_all/${cityActive}/${
	// 				groomerActive === "Paula" ? "paola" : groomerActive
	// 			}`
	// 		)
	// 		.then((response) => {
	// 			console.log(response);
	// 			let appointments = response.data;
	// 			for (let i = 0; i < appointments.length; i++) {
	// 				appointments[i].start = convertDate(appointments[i].start);
	// 				appointments[i].end = convertDate(appointments[i].end);
	// 			}
	// 			setDatesFiltered(
	// 				appointments.filter((availTime) =>
	// 					petTypeActive === "Dog"
	// 						? availTime.appointment === "online" &&
	// 						  availTime.start > new Date() &&
	// 						  !availTime.isBeingBooked
	// 						: petTypeActive === "Cat"
	// 						? availTime.appointment === "onlineCat" &&
	// 						  availTime.start > new Date() &&
	// 						  !availTime.isBeingBooked
	// 						: availTime.appointment === "onlineBath" &&
	// 						  availTime.start > new Date() &&
	// 						  !availTime.isBeingBooked
	// 				)
	// 			);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

	const getSoonestAppointment = async () => {
		await axios
			.get(
				`/schedule/calendar/closest_appointment/${city.toLocaleLowerCase()}/${petType}/${
					groomer === "Paula" ? "paola" : groomer.toLowerCase()
				}`
			)
			.then((res) => {
				setSoonestAppointment(res.data)
			})
			.catch((err) => console.log(err))
	}

	const getGroomerHours = async () => {
		toggleModal()

		getSoonestAppointment()
		const secondParam = dogService === "Bath" ? "tristen" : ""
		await axios
			.get(
				`/schedule/calendars_all/${city}/${
					groomer === "Paula" ? "paola" : groomer
				}/${secondParam}`
			)
			.then((response) => {
				let appointments = response.data
				for (let i = 0; i < appointments.length; i++) {
					appointments[i].start = convertDate(appointments[i].start)
					appointments[i].end = convertDate(appointments[i].end)
				}
				setDatesFiltered(
					appointments.filter((availTime) =>
						petType === "Dog" && dogService === "Bath" && groomer === "Nicole"
							? availTime.appointment === "onlineBath" &&
							  availTime.start > new Date() &&
							  !availTime.isBeingBooked &&
							  !availTime.deletedAt
							: petType === "Dog"
							? availTime.appointment === "online" &&
							  availTime.start > new Date() &&
							  !availTime.isBeingBooked &&
							  !availTime.deletedAt
							: availTime.appointment === "onlineCat" &&
							  availTime.start > new Date() &&
							  !availTime.isBeingBooked &&
							  !availTime.deletedAt
					)
				)
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	//Opens up modal with event info to update
	const handleEventGetter = async (event) => {
		let id = event.id
		// const obj = {
		// 	isBeingBooked: true,
		// 	id,
		// };
		setModalToSchedule(false)
		if (dogSize === "bigDogRequest" && groomer === "Mercedes") {
			return alert(
				"Mercedes can only groom medium and small dogs, please select a different groomer."
			)
		}
		toggleToEdit()

		await axios
			.get(`/schedule/calendar/one/${id}`)
			.then((res) => {
				setEventToEdit(res.data)
				setEditStart(res.data.start)
				setEditEnd(res.data.end)
			})
			.catch((error) => console.log(error))

		// Update Is being booked field for socket.io
		// await axios
		// 	.put(`/schedule/calendar/update/${event.id}`, obj)
		// 	.then(async () => {
		// 		// messageToSend("updating");
		// 		toggleToEdit();
		// 		await axios
		// 			.get(`/schedule/calendar/one/${id}`)
		// 			.then((res) => {
		// 				setEventToEdit(res.data);
		// 				setEditStart(res.data.start);
		// 				setEditEnd(res.data.end);
		// 			})
		// 			.catch((error) => console.log(error));
		// 	})
		// 	.catch((err) => console.log(err));
	}

	// Book appointment handler STARTS ******************************************************************
	const onSubmitModalToEdit = async (e) => {
		e.preventDefault()
		const petService = petType === "Dog" ? service : catService

		if (!petName || !breed || !phoneNumber || !email || !petService) {
			return setErrorMsg("All fields are required!")
		}

		if (isNewClient === "") {
			return setErrorMsg("Has your pet been here before?")
		}

		let appointmentType

		if (dogSize === "appRequest" && dogService === "Bath") {
			appointmentType = "bathSmallDog"
		} else if (dogSize === "appRequest") {
			appointmentType = "appRequest"
		}
		if (dogSize === "mediumDogRequest" && dogService === "Bath") {
			appointmentType = "bathMediumDog"
		} else if (dogSize === "mediumDogRequest") {
			appointmentType = "mediumDogRequest"
		}
		if (dogSize === "bigDogRequest" && dogService === "Bath") {
			appointmentType = "bathBigDog"
		} else if (dogSize === "bigDogRequest") {
			appointmentType = "bigDogRequest"
		}
		if (petType === "Cat") {
			appointmentType = "cat"
		}

		let isClientNew
		if (isNewClient === "No") {
			isClientNew = "New Client"
		} else if (isNewClient === "Yes") {
			isClientNew = "Existing Client"
		}

		let obj = {
			id: eventToEdit.id,
			title:
				"Ph#: " +
				phoneNumber.replace(/[- )(]/g, "") +
				" " +
				isClientNew +
				"--" +
				petService +
				" " +
				email,
			breedName: petName.toLowerCase() + " " + breed.toLowerCase(),
			start: editStart,
			end: editEnd,
			appointment: appointmentType,
			lastModifiedBy: "Client online booking",
			email: email,
			appDate: moment(editStart).format("dddd, MMMM Do YYYY, h:mm a"),
			appLocation:
				city === "milton"
					? "264 Bronte St S, Unit 15, Milton, ON"
					: "383 Elgin St N, Unit #1, Cambridge, ON",
			groomer: groomer,
			petName: petName.toUpperCase(),
			isBeingBooked: false,
		}

		let id = eventToEdit.id
		setLoadingAxiosReq(true)

		await axios
			.put(`/schedule/online/calendars_all/${id}`, obj)
			.then(() => {
				// messageToSend("booking");
				// socket.emit("send_message", {
				// 	message: "Event from schedule page",
				// 	room: "client_online",
				// })
				setModalSuccess(true)
				setModalToEditEvent(false)
				setModalToSchedule(false)
				setLoadingAxiosReq(false)
			})
			.catch((error) => {
				console.log(error)
				setLoadingAxiosReq(false)

				alert(
					"We are sorry, something went wrong! Please give us a call at 905 878 9009 or 905 878 5557"
				)
			})
	}
	// Book appointment handler ENDS *************************************************************************

	const eventStyleGetter = (event, start, end, isSelected) => {
		const style = {
			backgroundColor: "rgb(19, 235, 55)",
			borderRadius: "5px",
			opacity: 0.8,
			fontSize: "16px",
			color: "black",
			border: "1px solid blue",
			display: "block",
			paddingLeft: "12px",
		}

		return {
			style,
		}
	}

	const closeWindow = () => {
		window.location.reload()
	}

	const toggleShowPetType = () => {
		setShowPetType(true)
		setShowDogService(false)
		setShowCatServices(false)
		setShowGroomers(false)
		setShowDogSize(false)
	}
	const toggleShowDogSize = () => {
		setShowDogSize(true)
		setShowPetType(false)
		setShowDogService(false)
		setShowGroomers(false)
	}
	const toggleShowDogService = () => {
		setShowDogService(true)
		setShowDogSize(false)
		setShowPetType(false)
		setShowGroomers(false)
		setGroomer("")
	}
	const toggleShowCatService = () => {
		setShowCatServices(true)
		setShowDogSize(false)
		setShowPetType(false)
		setShowGroomers(false)
	}
	const toggleShowGroomers = () => {
		setShowGroomers(true)
		setShowDogSize(false)
		setShowPetType(false)
		setShowDogService(false)
	}

	return (
		<section
			id="schedule-containerId"
			className="relative flex flex-col items-center justify-center w-full h-full"
		>
			<Helmet>
				<title>Booking | Amazing Pet Grooming</title>
				<meta name="description" content="Book your appointments online" />
			</Helmet>

			<main className="w-full h-[100vh] md:h-[calc(100vh-90px)] flex">
				<div className="w-full lg:w-[60%] h-full bg-white pt-24 flex flex-col">
					<span className="w-full text-main border-b-[1px] border-main block pt-phone px-phone md:px-tabler lg:px-desktop">
						🗓️ Schedule Grooming Appointment
					</span>

					{/* Tags selected options */}
					<div className="w-full p-phone md:px-tablet lg:pl-desktop flex gap-[10px] flex-wrap">
						{city !== "" && (
							<Tag
								value={city === "milton" ? "Milton" : "Cambridge"}
								onClick={() => {
									resetPetType()
									setCity("")
								}}
								hasIcon
							/>
						)}
						{petType !== "" && (
							<Tag value={petType} onClick={toggleShowPetType} hasIcon />
						)}
						{dogSize !== "" && (
							<Tag
								value={
									dogSize && dogSize === "appRequest"
										? "Small Size"
										: dogSize === "mediumDogRequest"
										? "Medium Size"
										: dogSize === "bigDogRequest"
										? "	Large/X-Lg Size"
										: dogSize === "bigDogRequest"
										? "Large/X-Lg Size"
										: ""
								}
								onClick={toggleShowDogSize}
								hasIcon
							/>
						)}
						{dogService !== "" && dogService !== false && (
							<Tag value={dogService} onClick={toggleShowDogService} hasIcon />
						)}
						{catService !== "" && catService !== false && (
							<Tag value={catService} onClick={toggleShowCatService} hasIcon />
						)}
						{groomer !== "" && (
							<Tag
								value={groomer !== "bath_Cambridge" ? groomer : "Bather"}
								onClick={toggleShowGroomers}
								hasIcon
								disabled={petType === "Cat" ? true : false}
							/>
						)}
						<Tag value="Reset and start over" onClick={resetPetType} />
					</div>

					{/* Form */}
					<div className="w-full h-full flex flex-col p-phone md:p-tablet lg:px-desktop gap-[50px]">
						{/* City */}
						{!city && (
							<City
								onClickMilton={() => {
									setCity("milton")
									localStorage.setItem("CITY", "milton")
								}}
								onClickCambridge={() => {
									setCity("cambridge")
									localStorage.setItem("CITY", "cambridge")
								}}
							/>
						)}

						{/* Pet type */}
						{city !== "" && showPetType && (
							<PetTypeScreen
								onClickCat={() => {
									setShowPetType(false)
									setShowDogSize(false)
									setShowDogService(false)
									setDogService("")
									setShowCatServices(true)
									setGroomer("Paula")
									setDogSize("")
									setPetType("Cat")
									localStorage.setItem("PET_TYPE", "Cat")
								}}
								onClickDog={() => {
									setShowPetType(false)
									setShowDogSize(true)
									setShowCatServices(false)
									setPetType("Dog")
									setCatService("")
									setGroomer("")
									localStorage.setItem("PET_TYPE", "Dog")
								}}
							/>
						)}

						{/*************************************  Dog *************************************/}
						{/* Dog size */}
						{petType !== "" && showDogSize && (
							<DogSizeScreen functionCalled={onSelectedDogSize} />
						)}

						{/* Dog services */}
						{dogSize !== "" && showDogService && (
							<DogServices
								onClickGrooming={() => {
									setDogService("Grooming")
									setShowDogService(false)
									setShowGroomers(true)
									setGroomer("")
									// Do not allow to choose groomer
								}}
								onClickBath={() => {
									setDogService("Bath")
									setShowDogService(false)
									setShowGroomers(true)
								}}
							/>
						)}

						{/*************************************  Cat *************************************/}
						{/* Cat services */}
						{petType !== "" && showCatServices && (
							<CatServices functionCalled={onSelectedCatServices} />
						)}

						{/* Groomers */}
						{dogService !== "" && showGroomers && (
							<Groomers
								city={city}
								dogService={dogService}
								petType={petType}
								functionCalled={onSelectGroomer}
							/>
						)}

						{/* Validate answers */}
						{petType === "Dog" &&
							!showPetType &&
							!showDogSize &&
							!showDogService &&
							!showGroomers &&
							city !== "" &&
							petType !== "" &&
							dogSize !== "" &&
							dogService !== "" &&
							dogService !== false &&
							groomer !== "" && (
								<Validation
									onClick={() => {
										getGroomerHours()
									}}
									answers={finalAsnwers}
								/>
							)}

						{petType === "Cat" &&
							!showPetType &&
							!showCatServices &&
							!showGroomers &&
							city !== "" &&
							petType !== "" &&
							catService !== "" &&
							catService !== false &&
							groomer !== "" && (
								<Validation
									onClick={() => {
										getGroomerHours()
									}}
									answers={finalAsnwers}
								/>
							)}
					</div>
				</div>

				{/* Side image */}
				<div
					className="w-[40%] h-full relative rounded-l-default hidden lg:block overflow-hidden"
					style={{
						background: `radial-gradient(50% 50.00% at 50% 50.00%, rgba(101, 191, 248, 0.50) 0%, #65BFF8 100%)`,
					}}
				>
					<img
						src="/images/calendar-3d.png"
						className="w-[80%] absolute right-0 bottom-0"
					/>
				</div>
			</main>

			{/* Modal to schedule */}
			<div
				className={`w-[100vw] h-[100vh] bg-black/50 fixed top-0 items-center justify-center z-[9999] ${
					modalToSchedule ? "flex" : "hidden"
				}`}
				onClick={() => setModalToSchedule(false)}
			>
				<div
					className="w-[95%] h-[95%] md:w-[70%] md:h-[90%] bg-white rounded-default flex flex-col"
					onClick={(e) => e.stopPropagation()}
				>
					{/* Header */}
					<div className="w-full flex flex-col gap-[20px] border-b-[1px] border-gray px-phone pt-phone pb-[10px]">
						<div className="flex justify-between w-full">
							{/* Groomer */}
							<div className="flex gap-[10px] items-center">
								<div className="w-[70px] h-[70px] rounded-full overflow-hidden">
									<ImageGradient
										color="#65BFF8"
										image={`/images/${groomer.toLowerCase()}-main.png`}
										bigImage
									/>
								</div>
								<div className="flex flex-col items-start justify-center">
									<h5 className="text-[18px] leading-[16px] font-medium text-black mb-0">
										{groomer === "bath_Cambridge" ? "Bather" : groomer}
									</h5>
									<span className="mb-0 text-gray">Groomer</span>
								</div>

								{soonestAppointment && (
									<div className="flex h-full p-2 text-sm text-center border-2 rounded-sm lg:ml-20 sm:ml-10 bg-blue-50 border-cyan-500">
										<p className="flex flex-col items-center justify-center h-full p-auto">
											First available appointment: <br />
											<b>
												{moment(soonestAppointment.start).format(
													"MMMM Do YYYY, h:mm a"
												)}
											</b>
											<button
												onClick={async () => {
													setModalToSchedule(false)
													toggleToEdit()
													await axios
														.get(
															`/schedule/calendar/one/${soonestAppointment.id}`
														)
														.then((res) => {
															setEventToEdit(res.data)
															setEditStart(res.data.start)
															setEditEnd(res.data.end)
														})
														.catch((error) => console.log(error))
												}}
												className="p-2 mt-2 text-white bg-green-500"
											>
												Select this appointment
											</button>
										</p>
									</div>
								)}
							</div>
							{/* Close Button */}
							<div
								className="cursor-pointer"
								onClick={() => setModalToSchedule(false)}
							>
								<AiOutlineCloseCircle size={20} color="#2A2A2A" />
							</div>
						</div>

						{/* Instructions */}
						<div className="flex justify-between w-full">
							<p className="mb-0 font-medium text-left text-black align-middle">
								Click on the desired available time{" "}
								<span className="w-[16px] h-[16px] inline-block rounded-[2px] bg-green-400 mr-[5px]" />
								<span className="font-normal text-gray">
									Note: (Do not click and drag)
								</span>
							</p>
							<div
								className="flex gap-[5px] items-center relative cursor-pointer"
								onClick={() => {
									setShowInstructions(!showInstructions)
								}}
							>
								<AiOutlineInfoCircle size={20} color="#6B6B6B" />
								<p className="mb-0 text-gray">Show instructions</p>
								{showInstructions && (
									<div className="p-phone bg-lightBlue border-[1px] border-main absolute w-[300%] right-0 top-full z-[300] rounded-default rounded-tr-[0px]">
										<p className="mb-0 text-black">
											Select Date; available times are green.{" "}
											<span className="w-[16px] h-[16px] inline-block rounded-[2px] bg-green-400 mr-[5px]" />{" "}
										</p>
										<span className="text-green-500 bg-green-200 px-[2px]">
											Click on the day to see more the times.
										</span>
										<p className="mb-0">
											If you don't see any green spots, go to the next month or
											click on the X to select another groomer. <br />
											<span className="text-sm text-main">
												Should you require any assistance, please give us a call
												at 905 878-9009 / 905 878-5557.
											</span>
										</p>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Calendar */}
					<div className="w-full h-full flex flex-col gap-[20px] p-phone">
						<h5 className="mb-0 font-medium text-center text-black">
							Select day and then time
						</h5>
						<Calendar
							style={{
								height: "100%",
								background: "white",
							}}
							events={datesFiltered}
							selectable
							eventPropGetter={eventStyleGetter}
							timeslots={4}
							defaultView="month"
							views={["month", "day"]}
							defaultDate={new Date()}
							localizer={localizer}
							min={new Date(2019, 10, 0, 7, 0, 0)}
							max={new Date(2019, 10, 0, 16, 0, 0)}
							onSelectEvent={handleEventGetter}
						/>
					</div>
				</div>
			</div>

			{/* Modal To Edit Events */}
			<Modal
				isOpen={modalToEditEvent}
				toggle={toggleToEdit}
				modalClassName="modal-open-client-calendar custom-modal"
			>
				<ModalHeader toggle={handleEventGetter}>Enter your details</ModalHeader>
				<ModalBody>
					{/* <Countdown date={Date.now() + 300000} /> */}
					<h5>
						{" "}
						Appointment Details: <br />
						<b> {moment(editStart).format("dddd, MMMM Do YYYY, h:mm a")}</b>
						<br />
						Location:{" "}
						<span className="important-size-cambridge">
							{selectedCity}
						</span>{" "}
					</h5>
					<Form onSubmit={onSubmitModalToEdit}>
						<FormGroup row>
							<Label for="email" sm={4}>
								Email
							</Label>
							<Col sm={8}>
								<Input
									type="email"
									name="email"
									id="email"
									value={email}
									placeholder="Email address"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="petName" sm={4}>
								Pet Name
							</Label>
							<Col sm={8}>
								<Input
									type="text"
									name="petName"
									id="petName"
									value={petName}
									placeholder="Pet Name"
									onChange={(e) => setPetName(e.target.value)}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="breed" sm={4}>
								Breed
							</Label>
							<Col sm={8}>
								<Input
									type="text"
									name="breed"
									id="breed"
									value={breed}
									placeholder="Breed"
									onChange={(e) => setBreed(e.target.value)}
								/>{" "}
							</Col>
						</FormGroup>
						{petType === "Dog" && (
							<FormGroup row>
								<Label for="breed" sm={4}>
									Service
								</Label>
								<Col sm={8}>
									<Select
										options={
											dogService === "Grooming"
												? optionsDogServices
												: optionsDogBath
										}
										placeholder="Service"
										isSearchable={false}
										onChange={onSelectedDogServices}
									/>
								</Col>
							</FormGroup>
						)}

						<FormGroup row>
							<Label for="phoneNumber" sm={4}>
								Phone Number
							</Label>
							<Col sm={8}>
								<Input
									type="text"
									name="phoneNumber"
									id="phoneNumber"
									value={phoneNumber}
									placeholder="Phone Number"
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>{" "}
							</Col>
						</FormGroup>
						<div className="first-time-client-wrapper">
							<Select
								options={optionsIsNewClient}
								placeholder="Has your pet been here before?"
								isSearchable={false}
								onChange={onSelectedIsNewClient}
							/>
						</div>

						{<p className="err-msg-schedule">{errorMsg}</p>}
						{loadingAxiosReq ? (
							<p>Please wait...</p>
						) : (
							// Button to Book appointment ******************************************
							<Button
								id="google-book-appointment-btn"
								color="info"
								style={{ marginTop: "1rem" }}
								block
								className="continue-schedule-btn"
							>
								BOOK YOUR APPOINTMENT
							</Button>
							// Button to Book appointment ENDS ******************************************
						)}
						{/* <FormGroup></FormGroup> */}
					</Form>
				</ModalBody>
			</Modal>

			{/* Modal to edit events ends here */}
			{/* Modal Success */}
			<Modal size="lg" isOpen={modalSuccess} modalClassName="modal-success-app">
				<ModalBody>
					<div className="text-center modal-success-container">
						<h1 className="success-heading-modal">
							Appointment Successfully booked!
						</h1>
						<hr />
						<h4>
							A confirmation email will be sent to {email} shortly.
							<br />
							<span className="spam-folder-msg">
								You might have to check your spam or junk folder
							</span>
						</h4>
						<h4>Appointment Details</h4>
						<p>
							Location:{" "}
							<span className="important-size-cambridge">{selectedCity}</span>{" "}
						</p>
						<p> {moment(editStart).format("dddd, MMMM Do YYYY, h:mm a")}</p>
						<p>Groomer: {groomer}</p>
						<hr className="bg-white" />
						<p className="text-lg">
							<span className="text-yellow-300">Important:</span> <br />
							If this is your pet's first time with us, and you haven't
							registered yet, please <b>register online</b> before your
							appointment date; this will help speed everything up on the day of
							your appointment.
							<br />
							<button
								onClick={() => {
									resetPetType()
									setCity("")
									setModalSuccess(false)
									setShowRegisterGroomingModal(true)
								}}
								className="px-2 bg-green-500 rounded-sm mr-2"
							>
								Click here to register now.
							</button>
						</p>
						<hr className="bg-white" />
						<p>
							Should you need to cancel or modify your appointment, please give
							us a call at <br />{" "}
							{city === "milton"
								? "905 878 9009 or 905 878 5557"
								: "647 368 7220"}
						</p>
						<button className="modal-success-close-btn" onClick={closeWindow}>
							Close window
						</button>
					</div>
				</ModalBody>
			</Modal>
		</section>
	)
}

export default Schedule
